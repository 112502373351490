<template>
    <v-container class="container-main">
        <tool-bar :toolbar="toolbar"></tool-bar>
        <!-- class="data-table-header"
            :search="search"
            fixed-header
            :height="tableHeight"
            :headers="userHeader"
            :items="displayTsuikaUsers"
            :items-per-page="50" -->
        
        <v-data-table
            class="data-table-header"
            :search="search"
            :headers="userHeader"
            :items="displayTsuikaUsers"
            :height="tableHeight"
            :items-per-page="50"
            :footer-props="footerProps"
            fixed-header
        >

        </v-data-table>
        <!-- <v-simple-table fixed-header :height="tableHeight" class="mainTable">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center simple-table=header">{{ $t('global.userId') }}</th>
                        <th class="text-center simple-table=header">{{ $t('global.accountType') }}</th>
                        <th class="text-center simple-table=header">{{ $t('global.name') }}</th>
                        <th class="text-center simple-table=header">{{ $t('global.email') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(user, userInd) in displayTsuikaUsers" :key="userInd + 'userSettings'">
                        <td class="text-center">{{user.UserId}}</td>
                        <td class="text-center">{{user.account_type}}</td>
                        <td class="text-center">{{user.Name}}</td>
                        <td class="text-center">{{user.MailAddress}}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table> -->

        <v-dialog
            v-model="errorDialog"
            persistent
            width="400"
            @keydown.esc="errorDialog = false"
            scrollable
        >
            <v-card>
                <v-card-title class="card-title">
                    <v-icon color="#b11515">mdi-alert-circle</v-icon>
                    <span class="title-headline ml-2"><b>NOTICE</b></span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text class="card-text">
                    <v-container>
                        <center><h4 style="color: #666666">Data from GC Portal is not working</h4></center>
                        <center><h5 style="color: #666666; margin-top: -12px">Please refresh the page</h5></center>
                        <center><h5 style="color: #666666; margin-top: -8px">( <i>If it still not working. Please contact the admin</i> )</h5></center>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-overlay :value="loadMore" overlay>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>
<script>
    import { mapActions, mapState } from 'vuex'
    // import router from '../../router/index'
    import Toolbar from '../includes/Toolbar.vue'

    export default {
        components: {
            "tool-bar": Toolbar,
        },


        data: () => ({
            toolbar: {
                title: 'toolbar.homeTitle',
                sub_title: 'toolbar.userSettings',
                hasSearch: true,
            },
            errorDialog: false,
            loadMore: false,
            // tsuikaUserData: [],
            tableHeight: window.innerHeight - 210,
        }),
        computed: {
            ...mapState(['editMode', 'tsuikaUserData', 'search']),
            userHeader() {
                return [
                    {
                        text: this.$t('global.userId'),
                        align: 'center',
                        value: 'UserId',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('global.accountType'),
                        align: 'center',
                        value: 'account_type',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('global.name'),
                        align: 'center',
                        value: 'Name',
                        class: 'white--text title'
                    },
                    {
                        text: this.$t('global.email'),
                        align: 'center',
                        value: 'MailAddress',
                        class: 'white--text title'
                    }
                ]
            },
            displayTsuikaUsers(){
                let userData = [...this.tsuikaUserData], newUsers = [];
                if(this.tsuikaUserData.length) {
                    userData = userData.sort((a, b) => a.UserId.localeCompare(b.UserId, undefined, { numeric: true, sensitivity: 'base' }))
                    newUsers = userData.filter(r => r.UserId != '123'); //removing of testing emails for gc portal;
                }
                return newUsers;
            },
            footerProps(){
                let userData = this.$store.state.loggedInUser
                let findFilteredData = this.displayTsuikaUsers.filter(res => res.user_email == userData.email || res.assigned_to == userData.user_id);
                
                const len = findFilteredData.length / 50;
                let arr = [];
                let allData = [-1];
                var foorterNum = 0;
                if(len % 0 != 0) {
                    foorterNum = Math.trunc(len) + 1;
                }
                for(let i = 1; i <= foorterNum; i++) {
                    arr.push(i * 50)
                }

                if(arr.length == 1) {
                    arr.push(100)
                } 

                return {'items-per-page-options': arr.concat(allData)};
            },
        },
        methods: {
            ...mapActions(['getTsuikaUsers']),
        },
        watch: {
            displayTsuikaUsers(val) {
                if(val.length) {
                    this.loadMore = false;
                }
            },
        },
        mounted() {
            this.getTsuikaUsers();
        },
        created() {
            if(!this.tsuikaUserData.length) {
                this.loadMore = true;
            }
        this.$store.state.search = '';
        },
    }


    

</script>
<style scoped>
.v-data-table ::v-deep th {
    font-size: 13px !important;
    background-color: #274761 !important;
    font-family: 'Arial' !important;
    text-align: center !important;
    border: 1px solid white !important;
}
</style>